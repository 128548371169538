import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import "tachyons/css/tachyons.min.css";
import "../../css/common.css";

import Header from "../../components/Header";
import TitlePage from "../../components/ui/TitlePage";
import SectionTextPage from "../../components/ui/SectionTextPage";
import Footer from "../../components/Footer";

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <SectionTextPage>
        <TitlePage category="cookies" title={t("privacy-policy.title")} />
        <p className="section-subtitle">{t("privacy-policy.subtitle")}</p>
        <p>Text to be defined ...</p>
      </SectionTextPage>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
